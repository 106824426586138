import React from "react";

const Flags = ({ lang }) => {
  return (
    <>
      {lang === "tn" && (
        <div className="flex w-full justify-center z-10">
          <a className="ml-2" href={`/fr`}>
            <img src={require(`../assets/flag_fr.png`)} />
          </a>
          <a href={`/en`}>
            <img src={require(`../assets/flag_en.png`)} />
          </a>
        </div>
      )}
      {lang === "fr" && (
        <div className="flex w-full justify-center z-10">
          <a className="ml-2 mr-2" href={`/`}>
            <img src={require(`../assets/flag_tn.png`)} />
          </a>
          <a href={`/en`}>
            <img src={require(`../assets/flag_en.png`)} />
          </a>
        </div>
      )}
      {lang === "en" && (
        <div className="flex w-full justify-center z-10">
          <a className="ml-2 mr-2" href={`/`}>
            <img src={require(`../assets/flag_tn.png`)} />
          </a>
          <a href={`/fr`}>
            <img src={require(`../assets/flag_fr.png`)} />
          </a>
        </div>
      )}
    </>
  );
};

export default Flags;
