import React from "react";
import { ReactComponent as CursorSVG } from "../cursor.svg";

export default function InputControl({
  getResponse,
  setPrompt,
  prompt,
  loadingResponse,
  enabled,
  lang,
}) {
  const holder = {
    tn: "أكتب هنا",
    fr: "Taper ici",
    en: "Type here",
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    getResponse();
  };
  return (
    <form onSubmit={handleSubmit} className="flex space-x-4">
      <div className="grow">
        <input
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          type="text"
          placeholder={holder[lang]}
          className="input w-full"
          disabled={enabled}
        />
      </div>
      <button
        type="submit"
        disabled={!prompt || loadingResponse}
        className={`btn btn-ghost ${loadingResponse && "loading"}`}
      >
        {!loadingResponse && <CursorSVG />}
      </button>
    </form>
  );
}
