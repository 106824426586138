// src/AppRouter.js
import React from "react";
import { BrowserRouter , Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Login from "./Login";
import UserPage from "../pages/UserPage";
import Dashboard from '../pages/Dashboard'
import { RequireAuth } from "../components/RequireAuth";
import { PocketProvider } from "../contexts/PocketContext";
import Details from "../pages/Details";

const AppRouter = () => {
  return (
    <PocketProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/fr" element={<Home lang="fr" />} />
          <Route path="/en" element={<Home lang="en" />} />
          <Route path="/u/:consultId" element={<UserPage />} />
          <Route index path="/_" element={<Login />} />
          <Route element={<RequireAuth />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/:id" element={<Details />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </PocketProvider>
  );
};

export default AppRouter;
