import React from "react";

const Logo = () => {
  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 flex justify-center items-center">
        <img className="absolute flex top-4" src={require('../assets/logo.png')} />
      </div>
    </div>
  );
};

export default Logo;
