import { useEffect, useState } from "react";
import { ReactComponent as CursorSVG } from "../cursor.svg";
//import avatar from '../assets/avatar.svg';

export default function ChatContainer({ chatHistory, lang }) {
  const [displayResponse, setDisplayResponse] = useState("");
  const [completedTyping, setCompletedTyping] = useState(false);
  //const avatar = "https://lh3.googleusercontent.com/a-/ALV-UjVEVOinZkUT2vaq9M7rmLf7UwORoQoJtMtRp03eUlN_2T8=s64-p-k-rw-no";
  const avatar = require("../assets/avatar.jpg");
  const name = {
    tn: "هادي",
    fr: "Hedi",
    en: "Hedi"
  };
  const you = {
    tn: "أنا",
    fr: "Moi",
    en: "Me"
  }
  useEffect(() => {
    if (!chatHistory?.length) {
      return;
    }

    setCompletedTyping(false);
    let i = 0;
    const stringResponse = chatHistory[chatHistory.length - 1].content;

    const intervalId = setInterval(() => {
      setDisplayResponse(stringResponse.slice(0, i));
      i++;

      if (i > stringResponse.length) {
        clearInterval(intervalId);
        setCompletedTyping(true);
      }
    }, 20);

    return () => clearInterval(intervalId);
  }, [chatHistory]);

  return (
    <div className="max-h-0">
      {chatHistory.map((message, messageIndex) => (
        <div key={messageIndex}>
          {message?.role === "user" && (
            <div className="chat chat-end">
              <div className="chat-image avatar">
                <div className="w-10 rounded-full">
                  <img src="https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y" />
                </div>
              </div>
              <div className="chat-header text-white z-10">{you[lang]}</div>
              <span className="chat-bubble whitespace-pre-line opacity-90">
                {message?.content}
              </span>
            </div>
          )}
          {messageIndex === chatHistory.length - 1 &&
            message?.role === "assistant" && (
              <div className="chat chat-start">
                <div className="chat-image avatar online">
                  <div className="w-10 rounded-full">
                    <img src={avatar} />
                  </div>
                </div>
                <div className="chat-header text-white z-10">{name[lang]}</div>
                <span className="chat-bubble whitespace-pre-line opacity-90">
                  {displayResponse}
                  {!completedTyping && <CursorSVG />}
                </span>
              </div>
            )}
          {message?.role === "assistant" &&
            messageIndex !== chatHistory.length - 1 && (
              <div className="chat chat-start">
                <div className="chat-image avatar online">
                  <div className="w-10 rounded-full">
                    <img src={avatar} />
                  </div>
                </div>
                <div className="chat-header text-white z-10">{name[lang]}</div>
                <span className="chat-bubble whitespace-pre-line opacity-90">
                  {message?.content}
                </span>
              </div>
            )}
        </div>
      ))}
    </div>
  );
}
